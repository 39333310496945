$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #1b1919;
$foreground-color: #222020;
$input-background: #232223;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: #913a47;
$theme-color-2: #aa5e6c;
$theme-color-3: #a5456d;
$theme-color-4: #843a47;
$theme-color-5: #9e777e;
$theme-color-6: #c07a6c;

$primary-color: #8f8f8f;
$secondary-color: #707070;
$muted-color: #696969;

$gradient-color-1 : #9e4653;
$gradient-color-2 : #913a47;
$gradient-color-3 : #913e4a;

$lp-bg-color-1 : #270303;
$lp-bg-color-2 : #3a0807;
$lp-bg-color-3 : #5a1312;
$lp-bg-color-4 : #580c0a;

$shadow-offsets-top : 1 3 10 14 19;
$shadow-blurs-top: 2 6 10 14 19;
$shadow-opacities-top: 0.1 0.3 0.6 0.7 0.8;

$shadow-offsets-bottom : 1 3 6 10 15;
$shadow-blurs-bottom: 3 6 6 5 6;
$shadow-opacities-bottom: 0.1 0.3 0.6 0.7 0.8;

$logo-path: "../../logos/white.svg";
$logo-path-mobile: "../../logos/mobile.svg";

$lp-logo-path-pinned: "../../logos/white-full.svg";
$lp-logo-path: "../../logos/white-full.svg";

@import "../_mixins.scss";
@import "../_vien.style.scss";

